import * as React from 'react';

import {
  createStyles,
  Fade,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';

import { default as backgroundResized } from '@assets/images/fogg-coffee-break.png?resize&sizes[]=400&sizes[]=600&sizes[]=800&sizes[]=1000&sizes[]=1200&sizes[]=1600&sizes[]=2000'; // tslint:disable-line

const styles = (theme: Theme) =>
  createStyles({
    landingContainer: {
      marginTop: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(8)
      },

      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: theme.breakpoints.values.md
    },
    title: {
      fontFamily: 'ratio, sans-serif'
    },
    subtitle: {
      maxWidth: 520
    },
    searchPaper: {
      position: 'relative',
      marginTop: theme.spacing(7),
      maxWidth: 550,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5)
      }
    },
    heroBackground: {
      width: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      zIndex: -2,
      position: 'absolute',
      right: 0,
      backgroundColor: '#fff'
    },
    hero: {
      width: 700,
      [theme.breakpoints.down('xs')]: {
        width: 600,
        top: theme.spacing(34)
      },

      top: theme.spacing(22),
      zIndex: -1,
      position: 'absolute',
      right: 0
    },

    svgContainer: {
      overflowX: 'hidden'
    }
  });

type IProps = WithStyles<typeof styles>;

const HomePage: React.FC<IProps> = ({ classes }) => {
  return (
    <>
      <div className={classes.heroBackground} />
      <Fade in timeout={700}>
        <img
          alt="Hero"
          className={classes.hero}
          srcSet={backgroundResized.srcSet}
          src={backgroundResized.src}
        />
      </Fade>
      <div className={classes.landingContainer}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          This domain is available for sale!
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          Contact <a href="mailto:support@valure.io">support@valure.io</a> for pricing inquiries.
        </Typography>
      </div>
    </>
  );
};

export default withStyles(styles)(HomePage);
